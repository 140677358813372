<template>
  <container>
    <div
      slot="bottom_box_content"
      style="padding:10px 10px;"
    >
      <component
        ref="bottom"
        :is="curBottomComponent"
      ></component>
    </div>
  </container>
</template>

<script>
import Container from '@/components/common/Container'
import { sysMixins } from '@/assets/mixins/sys'

export default {
  mixins: [sysMixins],
  components: {
    Container,
    Bottom: () => import('./components/Bottom')
  },
  data () {
    return {
    }
  },
  methods: {
  },
  watch: {
  },
  computed: {
  },
  mounted () {
    this.setLeftComponent('')
    this.setRightComponent('')
    this.setShowLeftBlock(false)
    this.setShowRightBlock(false)
    this.setShowMap(false)
    this.setBottomHeight('90%')
    this.setBottomComponent('Bottom')
  },
  destroyed () {
    this.setShowLeftBlock(false)
    this.setShowMap(false)
    this.setShowRightBlock(false)
    this.setBottomHeight('90%')
    this.setLinkTagArray()
    this.setLinkRouterMaps()
    this.setActivedTagName('')
  }
}
</script>
